import { SortModel } from '@dmc-ng/utils';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  CountingNumberVersionModel,
  CountingVersionModel,
} from '../models/counting-version.model';
import { CountingModel } from '../models/counting.model';
import { CreateCountingVersionModel } from '../models/create-counting-version.model';
import { CreateCountingModel } from '../models/create-counting.model';
import { FullCountingModel } from '../models/full-counting.model';
import { TokenParamsModel } from '../models/token.model';

export const countingsActions = createActionGroup({
  source: 'Countings',
  events: {
    'Get Paginated Countings': props<{
      organizationId: string;
      accountId: string;
      offset?: number;
      limit?: number;
      sort?: SortModel;
    }>(),
    'Paginated Countings retrieved successfully': props<{
      countings: CountingNumberVersionModel[];
      total: number;
      totalInCurrentOffset: number;
    }>(),
    'Paginated Countings failed to be retrieved': props<{
      error: unknown;
    }>(),

    'Create Counting': props<{
      organizationId: string;
      accountId: string;
      counting: CreateCountingModel;
      countingVersion?: CreateCountingVersionModel;
    }>(),
    'Counting created successfully': props<{
      counting: CountingModel;
      countingVersion?: CreateCountingVersionModel;
    }>(),
    'Counting failed to be created': props<{ error: unknown }>(),

    'Update Counting': props<{
      organizationId: string;
      accountId: string;
      countingId: string;
      counting: CreateCountingModel;
    }>(),
    'Counting updated successfully': props<{
      counting: CountingModel;
    }>(),
    'Counting failed to be updated': props<{ error: unknown }>(),

    'Get Counting with all versions': props<{
      organizationId: string;
      accountId: string;
      countingId: string;
    }>(),
    'Counting with all versions retrieved successfully': props<{
      counting: FullCountingModel;
    }>(),
    'Counting with all versions failed to be retrieved': props<{
      error: unknown;
    }>(),
    'Create Counting Version': props<{
      organizationId: string;
      accountId: string;
      countingId: string;
      countingVersion: CreateCountingVersionModel;
      fromCreate: boolean;
    }>(),
    'Counting Version created successfully': props<{
      countingVersion: CountingVersionModel;
      fromCreate: boolean;
    }>(),
    'Counting Version failed to be created': props<{ error: unknown }>(),

    'Delete Counting': props<{
      organizationId: string;
      accountId: string;
      countingId: string;
      offset?: number;
    }>(),
    'Counting delete successfully': props<{
      countingId: string;
      offset?: number;
    }>(),
    'Counting failed to be deleted': props<{ error: unknown }>(),

    'Get Countings with search': props<{
      organizationId: string;
      accountId: string;
      query?: string;
      limit?: number;
      withVersions?: boolean;
    }>(),

    'Countings search successfully': props<{
      countings: any;
    }>(),
    'Counting failed to be searched': props<{ error: unknown }>(),
    'Reset counting with search': emptyProps(),

    'Delete Counting Version': props<{
      organizationId: string;
      accountId: string;
      countingId: string;
      countingVersionId: string;
    }>(),
    'Counting Version delete successfully': props<{
      countingVersionId: string;
    }>(),
    'Counting Version failed to be deleted': props<{ error: unknown }>(),

    'Get token share': props<{
      organizationId: string;
      accountId: string;
      countingId: string;
      countingVersionId: string;
      tokenParams: TokenParamsModel;
    }>(),
    'Token share retrieved successfully': props<{ token: string }>(),
    'Token share failed to be retrieved': props<{ error: unknown }>(),
    'Reset counting details': emptyProps(),
  },
});
