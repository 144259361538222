import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  COUNTINGS_FEATURE_KEY,
  CountingsState,
  countingListAdapter,
  countingsDetailsAdapter,
  countingsSearchAdapter,
} from './countings.reducers';

export const selectCountingsState = createFeatureSelector<CountingsState>(
  COUNTINGS_FEATURE_KEY,
);

const { selectEntities: countingListEntities, selectAll: countingList } =
  countingListAdapter.getSelectors();

const { selectEntities: countingSearchEntities, selectAll: countingsSearch } =
  countingsSearchAdapter.getSelectors();

const {
  selectEntities: countingsDetailsEntities,
  selectAll: allCountingsDetails,
} = countingsDetailsAdapter.getSelectors();

export const selectCountingsDetailsState = createSelector(
  selectCountingsState,
  (state) => state.countingsDetails,
);

export const selectCountingListState = createSelector(
  selectCountingsState,
  (state) => state.countingList,
);

export const selecCountingListEntities = createSelector(
  selectCountingListState,
  countingListEntities,
);

export const selectCountingList = createSelector(
  selectCountingListState,
  countingList,
);

export const selectCountingSearchState = createSelector(
  selectCountingsState,
  (state) => state.countingsSearch,
);

export const selecCountingSearchEntities = createSelector(
  selectCountingSearchState,
  countingSearchEntities,
);

export const selectCountingSearch = createSelector(
  selectCountingSearchState,
  countingsSearch,
);

export const selectCountingsDetailsEntities = createSelector(
  selectCountingsDetailsState,
  countingsDetailsEntities,
);

export const selectAllCountingsDetails = createSelector(
  selectCountingsDetailsState,
  allCountingsDetails,
);

export const selectCountingsLoading = createSelector(
  selectCountingsState,
  (state) => state.loading,
);

export const selectTokenLoading = createSelector(
  selectCountingsState,
  (state) => state.loadingTokenShare,
);

export const selectCountingListLoading = createSelector(
  selectCountingListState,
  (state) => state.loading,
);

export const selectCountingSearchLoading = createSelector(
  selectCountingSearchState,
  (state) => state.loading,
);

export const selectCountingsDetailsLoading = createSelector(
  selectCountingsDetailsState,
  (state) => state.loading,
);

export const selectCountingError = createSelector(
  selectCountingsState,
  (state) => state.error,
);

export const selectCountingListError = createSelector(
  selectCountingListState,
  (state) => state.error,
);

export const selectCountingSearchError = createSelector(
  selectCountingSearchState,
  (state) => state.error,
);

export const selectCountingsDetailsError = createSelector(
  selectCountingsDetailsState,
  (state) => state.error,
);

export const selectCountingListTotal = createSelector(
  selectCountingListState,
  (state) => state.total,
);

export const selectCountingListTotalTotalInCurrentOffset = createSelector(
  selectCountingListState,
  (state) => state.totalInCurrentOffset,
);
