import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APIResponse, APIResponseValidation } from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { SortModel, SortUtils } from '@dmc-ng/utils';
import { Observable } from 'rxjs';

import {
  CountingPaginatedModel,
  CountingVersionModel,
} from './models/counting-version.model';
import { CountingModel } from './models/counting.model';
import { CreateCountingVersionModel } from './models/create-counting-version.model';
import { CreateCountingModel } from './models/create-counting.model';
import { FullCountingModel } from './models/full-counting.model';
import { TokenParamsModel, TokenShareModel } from './models/token.model';

@Injectable()
export class CountingsService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getAllCountings(
    organizationId: string,
    accountId: string,
    offset = 0,
    limit = 10,
    sort?: SortModel,
  ): Observable<APIResponse<CountingPaginatedModel>> {
    let params = new HttpParams()
      .append('offset', offset)
      .append('limit', limit);
    if (sort) {
      params = params.append('sort', SortUtils.createQueryParams(sort));
    }
    return this.httpClient.get<APIResponse<CountingPaginatedModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/`,
      { params },
    );
  }
  postCounting(
    organizationId: string,
    accountId: string,
    counting: CreateCountingModel,
  ): Observable<APIResponseValidation<CountingModel>> {
    return this.httpClient.post<APIResponseValidation<CountingModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/`,
      counting,
    );
  }

  putCounting(
    organizationId: string,
    accountId: string,
    countingId: string,
    counting: CreateCountingModel,
  ): Observable<APIResponseValidation<CountingModel>> {
    return this.httpClient.put<APIResponseValidation<CountingModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/${countingId}`,
      counting,
    );
  }

  getAllVersionsCounting(
    organizationId: string,
    accountId: string,
    countingId: string,
  ): Observable<APIResponse<FullCountingModel>> {
    return this.httpClient.get<APIResponse<FullCountingModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/${countingId}`,
    );
  }

  postNewVersionCounting(
    organizationId: string,
    accountId: string,
    countingId: string,
    newVersion: CreateCountingVersionModel,
  ): Observable<APIResponseValidation<CountingVersionModel>> {
    return this.httpClient.post<APIResponseValidation<CountingVersionModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/${countingId}/counting-versions`,
      newVersion,
    );
  }

  deleteCounting(
    organizationId: string,
    accountId: string,
    countingId: string,
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/${countingId}`,
    );
  }

  searchCountings(
    organizationId: string,
    accountId: string,
    query?: string,
    limit = 10,
    withVersions = false,
  ): Observable<APIResponse<FullCountingModel[]>> {
    let params = new HttpParams();
    params = params.append('limit', limit);
    params = params.append('with-versions', withVersions);

    if (query) {
      params = params.append('q', query);
    }
    return this.httpClient.get<APIResponse<FullCountingModel[]>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/all`,
      { params },
    );
  }

  deleteCountingVersion(
    organizationId: string,
    accountId: string,
    countingId: string,
    countingVersionId: string,
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/${countingId}/counting-versions/${countingVersionId}`,
    );
  }

  postTokenShare(
    organizationId: string,
    accountId: string,
    countingId: string,
    countingVersionId: string,
    tokenParams: TokenParamsModel,
  ): Observable<APIResponse<TokenShareModel>> {
    return this.httpClient.post<APIResponse<TokenShareModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/countings/${countingId}/counting-versions/${countingVersionId}/token`,
      tokenParams,
    );
  }
}
